import {
  CanvasModal,
  CanvasModalHeader,
} from "common/components/CanvasModal/CanvasModal";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { videoInit } from "modules/canvas/data/api/video/init.api";
import { uploadVideo } from "modules/canvas/data/api/video/upload.api";
import useCanvasFunctions from "modules/canvas/useCanvasFunctions";
import { useCanvasStateStore } from "pages/Canvas";
import React, { useCallback, useEffect, useState } from "react";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../MainNode/MainNode";
import "./UploadingYourVideoModal.scss";
import { useOutComesStore } from "../OutcomeNode";
import axios from "axios";
import { genS3Url } from "modules/canvas/data/api/video/genS3URL";

interface UploadingYourVideoModalProps {
  isOpen?: boolean | undefined;
  onClose(value: boolean): void;
}

const CHUNK_SIZE = 3 * 1024 * 1024;
export const UploadingYourVideoModal = ({
  isOpen,
  onClose,
}: UploadingYourVideoModalProps) => {
  const { file, setPage, currNodeId } = useVideoModalStore();
  const { flowId } = useCanvasStateStore();
  const [currProgress, setCurrProgress] = useState(0);
  const { saveNodeData, getNodeData } = useCanvasFunctions();
  const { editMultipleOutCome, editSingleOutcome, currentOutcomeType } =
    useOutComesStore();

  useEffect(() => {
    const i = async () => {
      if (!file || !flowId || !isOpen || !currNodeId) {
        console.log("No File! ", file);
        return;
      }
      let prevProgress = 0;
      let S3URL = await genS3Url(flowId);
      await axios.put(S3URL.data.presignedUrl, file, {
        headers: { "Content-Type": "video/*" },
        onUploadProgress(progressEvent) {
          console.log(progressEvent);
          if (!progressEvent.progress || !progressEvent.total) return;
          const totalLength = progressEvent.total;
          prevProgress = Math.max(prevProgress, progressEvent.loaded);
          if (totalLength !== null) {
            setCurrProgress(Math.round((prevProgress * 100) / totalLength));
          }
        },
      });

      saveNodeData(
        {
          videoID: S3URL.data.video_id,
          shouldStartVideoEncoding: true,
          thumbnail: "",
          video: "",
        },
        currNodeId
      );

      const newData = {
        videoID: S3URL.data.video_id,
      };
      if (currentOutcomeType === "multiple")
        editMultipleOutCome(getNodeData(currNodeId)!.rootNodeId, {
          nodeData: newData,
        });
      else editSingleOutcome({ nodeData: newData });
      setTimeout(() => {
        setPage(VIDEO_MODAL_PAGES.NONE);
      }, 5);
    };
    i();
  }, [
    currNodeId,
    currentOutcomeType,
    editMultipleOutCome,
    editSingleOutcome,
    file,
    flowId,
    getNodeData,
    isOpen,
    saveNodeData,
    setPage,
  ]);

  return (
    <CanvasModal
      isOpen={isOpen}
      onClose={onClose}
      className="uploading-video-modal"
    >
      <CanvasModalHeader>
        <div className="uploading-video-modal__header">
          <h2 className="uploading-video-modal__heading">
            Uploading your video
          </h2>
        </div>
      </CanvasModalHeader>
      <div className="uploading-video-modal__body">
        <div className="uploading-video-modal__loading">
          <div className="loading__icon">
            <FontAwesomeIcon icon={solid("cloud-arrow-up")} />
          </div>
          <p>Just a moment please...</p>
          <div className="loading__loading-bar">
            <div
              className="loaded-bar"
              style={{ "--width": `${currProgress}%` } as React.CSSProperties}
            ></div>
          </div>
        </div>
      </div>
    </CanvasModal>
  );
};
